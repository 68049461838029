import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import "./plugins/snotify";
import "./plugins/crypto";
import "./plugins/plyr";
// import "./plugins/tailwind";

Vue.config.productionTip = false;

store.dispatch("auth/TRY_AUTO_LOGIN");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
