import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { auth } from "@/store/modules/auth";
import { profile } from "@/store/modules/profile";
import { ratings } from '@/store/modules/rating'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    profile,
    ratings
  }
})
