import { api } from '../../services/httpclient';

const state = {};

const getters = {};

const mutations = {};

const actions = {

    // COMENTARIOS

    async REGISTER_RATING({ commit }, payload) {
        try {
            const response = await api.post(`api/v1/auth/update`, payload);
            commit("auth/SET_AUTH_USER", response.data.data, { root: true });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async REGISTER_COMMENTARY(_, payload) {
        try {
            const response = await api.post(`api/v1/commentary`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async GET_COMMENTARY(_, payload) {
        try {
            const response = await api.get(`api/v1/commentary?page=${payload.page}&content=${payload.content_id}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    // RATINGS

    async GET_SCORE() {
        try {
            const response = await api.get(`api/v1/score`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async REGISTER_SCORE(_, payload) {
        try {
            const response = await api.post(`api/v1/score`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async ANSWER_TEST(_, payload) {
        try {
            const response = await api.post(`api/v1/questions/percentage`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async ANSWER_CUESTIONARY(_, payload) {
        try {
            const response = await api.post(`api/v1/questionnaire`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    

    async VERIFY_DOWLOAD() {
        try {
            const response = await api.get(`api/v1/completion_certificate/verify`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async DOWLOAD_CERTIFICATE() {
        try {
            const response = await api({
                url: `api/v1/completion_certificate`,
                method: 'GET',
                responseType: 'blob',
            });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

// exportar propiedades
export const ratings = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
