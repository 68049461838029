<template>
  <v-app class="bg-image">
    <router-view />
    <vue-snotify></vue-snotify>
    <!-- <footer-core /> -->
  </v-app>
</template>

<script>
// import FooterCore from "../src/components/core/Footer";

export default {
  components: {
    // "footer-core": FooterCore,
  },
  name: "App",
  data: () => ({}),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Montserrat", "Poppins", sans-serif;
  /* color: #4d4d4e; */
}

.text-title {
  font-weight: 600;
  font-size: 1em;
}

.text-sub-title {
  font-weight: 600;
  font-size: 2em;
}

.text-sub-title-regular {
  font-weight: 400;
  font-size: 1.5em;
}

.text-list-regular {
  font-weight: 400;
  font-size: 1.3em;
}

.text-regular {
  font-weight: 400;
  font-size: 2em;
}

.text-regular-semi {
  font-weight: 400;
  font-size: 1.5vmax;
}

/* .bg-image {
  background-image: url("../public/img/Fondo Interno.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
} */

.card-radius {
  border-radius: 90px !important;
}

.font-light {
  font-weight: 300 !important;
}

.card-custom {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #dbdbdb !important;
  border-radius: 18px !important;
}

.title-card {
  color: #929292;
  font-size: 22px;
}

.max-w-300 {
  max-width: 300px!important;
}

</style>
