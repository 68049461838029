import axios from 'axios';
import store from '../store';
import router from '../router';

/**
 * [cliente env url/api]
 */
export const api = axios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  headers: {
    'Access-Control-Allow-Origin': process.env.VUE_APP_BACK_URL,
  },
});

export const apiFile = axios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  headers: {
    Accept: "multipart/form-data",
    "Access-Control-Allow-Origin": process.env.VUE_APP_BACK_URL
  }
});

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.commit('auth/CLEAR_TOKEN');
    router.push({ name: 'Login' }).catch(() => {});
  }
  return Promise.reject(error)
});