import { api } from "../../services/httpclient";
import { apiFile } from "../../services/httpclient";

const state = {
  new_notifications: [],
  old_notifications: [],
  encrypt: {},
};

const getters = {
  GET_NEW_NOTIFICATION: (state) => state.new_notifications,
  GET_OLD_NOTIFICATION: (state) => state.old_notifications,
  HAS_NOTIFICATIONS: (state) =>
    state.new_notifications.length > 0 ? true : false,
  GET_KEYS: (state) => state.encrypt,
};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.new_notifications = payload.filter(
      (notification) => notification.status == 1
    );
    state.old_notifications = payload.filter(
      (notification) => notification.status == 0
    );
  },

  SEND_ENCRYPT(state, payload) {
    state.encrypt = {
      data_encrypt: payload.data_encrypt,
      public_key: payload.public_key,
    };
  },

  SET_UPDATE_NOTIFICATION(state, payload) {
    const indexUpdate = state.new_notifications.findIndex(
      (index) => index.id == payload.data.id
    );

    if (indexUpdate > -1) {
      state.new_notifications.splice(indexUpdate, 1);
    }

    state.old_notifications.push(payload.data);
  },
};

const actions = {
  async UPDATE_PROFILE({ commit }, payload) {
    try {
      const response = await api.post(`api/v1/auth/update`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PLAN_SERVICES() {
    try {
      const response = await api.get(`api/v1/plan`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_MODULES() {
    try {
      const response = await api.get(`api/v1/module`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async COMPLETE_MODULES() {
    try {
      const response = await api.get(`api/v1/complete_modules`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_LEVELS(_, payload) {
    try {
      const response = await api.get(
        `api/v1/list_module/level?module_id=${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_MENU(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_module?module_id=${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_DATA(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_level_module?module_id=${payload.module_id}&level=${payload.level}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_DATA_MY_WAY() {
    try {
      const response = await api.get(`api/v1/content_level_module/user`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_WATCHED_CONTENT() {
    try {
      const response = await api.get(
        `api/v1/content_level_module/watchedContent`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_BY_CODE_MODULE(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_level_module/contentByModuleId/${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_PENDING_CONTENT() {
    try {
      const response = await api.get(
        `api/v1/content_level_module/pendingContent`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_FAVORITE(_, payload) {
    try {
      const response = await api.post(`api/v1/content_favorite`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async MODULE_PERCENTAGE(_, payload) {
    try {
      const response = await api.post(
        `api/v1/path/user_percentage_module`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LEVEL_PERCENTAGE(_, payload) {
    try {
      const response = await api.post(
        `api/v1/path/user_percentage_path`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_MODULE_LEVEL({ commit }, payload) {
    try {
      const response = await api.post(`api/v1/module/update_user`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async VERIFY_PAY(_, payload) {
    try {
      const response = await api.post(`api/v1/plan_user/verify`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SHOW_CONTENT(_, payload) {
    // await console.log(
    //   payload,
    //   "Se comenrto este codigo para poder mantener las pildoras - PENDING"
    // );
    // return;
    try {
      const response = await api.post(`api/v1/content_user/time`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_NOTIFICATIONS({ commit }, payload) {
    try {
      const response = await api.get(`api/v1/notification`, payload);
      commit("SET_NOTIFICATIONS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PROFILE_PICTURE({ commit }, payload) {
    try {
      const response = await apiFile.post(`api/v1/auth/update_image`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ENCRYPT_CONTENT({ commit }, payload) {
    try {
      const response = await apiFile.post(
        `api/v1/content_user/content_encrypt`,
        payload
      );
      commit("SEND_ENCRYPT", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DESECRYPT_CONTENT(_, payload) {
    try {
      const response = await apiFile.post(`api/v1/content_user/token`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SEND_RESPONSE_PAY({ commit, dispatch }, payload) {
    try {
      const response = await apiFile.post(`api/v1/plan_user`, payload);
      commit("auth/SET_TOKEN", response.data.data.token, { root: true });
      commit("auth/SET_AUTH_USER", response.data.data.user, { root: true });
      dispatch("GET_NOTIFICATIONS");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async NEW_SEND_RESPONSE_PAY({ commit, dispatch }, payload) {
    try {
      const response = await apiFile.post(`api/v1/plan_user/store`, payload);
      commit("auth/SET_TOKEN", response.data.data.token, { root: true });
      commit("auth/SET_AUTH_USER", response.data.data.user, { root: true });
      dispatch("GET_NOTIFICATIONS");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_NOTIFICATION({ commit }, payload) {
    try {
      const response = await api.put(
        `api/v1/notification/${payload.id}`,
        payload
      );
      const request = {
        data: response.data.data[0],
        status: payload.status,
      };

      commit("SET_UPDATE_NOTIFICATION", request);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_FAVORITES(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_favorite?module_id=${payload.module_id}&level=${payload.level}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const profile = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
